<template>
  <div class="elv-table-base-category">
    <div v-for="(item, index) in props.textArr" :key="index" class="elv-table-base-category-text">{{ item }}</div>
  </div>
</template>
<script lang="ts" setup>
const props: any = defineProps<{ textArr: string[] }>()
</script>
<style lang="scss">
.elv-table-base-category {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #455785;
  font-family: 'Plus Jakarta Sans';
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  gap: 8px;

  &-text {
    padding: 4px 8px;
    border-radius: 3px;
    white-space: nowrap;
    border: 1px solid rgba(69, 87, 133, 0.35);
    background-color: rgba(69, 87, 133, 0.1);
    color: #455785;
    font-family: 'Plus Jakarta Sans';
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
</style>
