<template>
  <div class="elv-table-base-progress">
    <div class="elv-table-base-progress-bar">
      <el-progress :stroke-width="6" color="#7596EB" :show-text="false" :percentage="props.percentage"> </el-progress>
    </div>
    <div class="elv-table-base-progress-text">{{ props.text }}</div>
  </div>
</template>
<script lang="ts" setup>
const props: any = defineProps<{ percentage: number; text: string }>()
</script>
<style lang="scss">
.elv-table-base-progress {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  text-align: right;
  font-family: 'Barlow';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  .el-progress--line {
    // margin-bottom: 10px;
    width: 100px;
  }

  .elv-table-base-progress-text {
    margin-top: 4px;
  }
}
</style>
