<template>
  <div v-if="options?.length" class="elv-base-filter-text-icon-select">
    <span class="elv-base-filter-item__label">{{ title }}:</span>

    <el-select v-bind="$attrs" placeholder=" " :empty-values="[null, undefined]" popper-class="elv-base-filter-popper">
      <el-option v-for="item in props.options" :key="item.value" :label="item.label" :value="item.value">
        <img v-if="item.icon" :src="item.icon" />
        {{ item.label }}</el-option
      >
      <template #prefix>
        <img v-if="currentIcon" :src="currentIcon" />
        <!-- <span v-else class="el-base-filter-item-span">{{ currentTitle }}</span> -->
      </template>
    </el-select>
  </div>
</template>
<script setup lang="ts">
import { find } from 'lodash-es'

const props = defineProps({
  title: {
    type: String,
    default: ''
  },
  options: {
    type: Array as () => ElvSelectOptionType[],
    default: () => []
  }
})

const attrs: any = useAttrs()

const currentIcon = computed(() => {
  return find(props.options, { value: attrs.modelValue })?.icon || ''
})

// eslint-disable-next-line no-unused-vars
const currentTitle = computed(() => {
  return find(props.options, { value: attrs.modelValue })?.label || ''
})
</script>

<style lang="scss">
.elv-base-filter-text-icon-select {
  margin-right: 16px;
  display: flex;
  align-items: center;

  .elv-base-filter-item__label {
    font-size: 12px;
  }

  .el-base-filter-item-span {
    color: #1e2024;
    font-family: 'Plus Jakarta Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .el-select {
    height: 15px;
    width: fit-content;

    .el-select__wrapper {
      height: 15px;
      min-height: 15px;
      border: none !important;
      box-shadow: none !important;
      padding: 0;
      padding-left: 8px;

      .el-select__selection {
        width: fit-content;

        .el-select__placeholder {
          position: relative;
          top: 0;
          transform: none;
          color: #1e2024;
          font-family: 'Plus Jakarta Sans';
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          width: fit-content;
          overflow: visible;
          min-width: 10px;
        }
      }

      .el-select__prefix {
        img {
          width: 16px;
          height: 16px;
          display: block;
          border-radius: 50%;
        }
      }

      .el-select__suffix {
        .el-icon {
          margin-left: 4px;

          svg {
            width: 12px;
            height: 12px;
          }
        }
      }
    }
  }
}

.elv-base-filter-popper {
  .el-select-dropdown__item {
    color: #1e2024;
    font-family: 'Plus Jakarta Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    img {
      width: 18px;
      height: 18px;
    }
  }
}
</style>
